import { ComponentType, CSSProperties, Fragment } from 'react'
import React = require('react')

import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Category } from '../common/data-misc'
import { ProductDefinition } from '../common/data-product-defs'
import { ProductLink } from '../common/data-product-links'
import { getDataService, ProductDefinitionOps, TemplateDefinitionOps } from '../common/data-service'
import { TemplateDefinition } from '../common/data-template-defs'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { AttributeCombination } from '../common/types'
import { EditModalProps } from './definition-edit-modal'
import { DefinitionsMenu } from './definitions-menu'
import { Filter } from './filter'
import { FloatingHeader } from './floating-header'
import { MainMenu } from './main-menu'
import { User } from './user'
import { Utils } from './utils'

export interface DefinitionListProps {
  isProduction: boolean,
  menuTabId: string,
  editModal: ComponentType<EditModalProps>,
  productDefinitionModal?: ComponentType<EditModalProps>,
  definitionService: TemplateDefinitionOps | ProductDefinitionOps,
  getAllCommand?: () => Promise<ProductDefinition[]>,
  currencies: string[],
  attributeContextFilter: (attributeDefinition: AttributeDefinition) => boolean,
}

interface State {
  loaded: boolean,
  categories: Category[],
  unfilteredAttributeDefinitions: AttributeDefinition[],
  attributeDefinitions: AttributeDefinition[],
  definitions: (TemplateDefinition | ProductDefinition)[],
  productLinks: Record<string, ProductLink[]>,
  templateDefinitions: TemplateDefinition[] | null,
  expanded: Record<string, boolean>,
}

export class DefinitionList extends React.Component<DefinitionListProps, State> {
  state: State = {
    loaded: false,
    categories: [],
    unfilteredAttributeDefinitions: [],
    attributeDefinitions: [],
    definitions: [],
    productLinks: {},
    templateDefinitions: [],
    expanded: {},
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    Filter.registerEvent(this)

    const getAllCommand = this.props.getAllCommand || this.props.definitionService.getAll
    const DataService = getDataService()

    Promise.all([
      DataService.Categories.getAll(),
      DataService.AttributeDefinitions.getAll(),
      getAllCommand(),
      this.props.isProduction ? DataService.ProductLinks.getAll() : null,
      !this.props.isProduction ? DataService.TemplateDefinitions.getAll() : null,
    ])
    .then(([
      categories,
      attributeDefinitions,
      definitions,
      productLinks,
      templateDefinitions,
    ]) => {
      if (!this._isMounted) {
        return
      }

      const filteredAttrDefs = attributeDefinitions.filter(this.props.attributeContextFilter)

      this.sortDefinitions(definitions, categories)

      this.setState(
        {
          loaded: true,
          categories,
          unfilteredAttributeDefinitions: attributeDefinitions,
          attributeDefinitions: filteredAttrDefs,
          definitions,
          productLinks,
          templateDefinitions,
        },
        EventBus.fireFunc('definitions-rendered'),
      )
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    Filter.unregisterEvent(this)
  }

  reloadDefinitions = () => {
    const getAllCommand = this.props.getAllCommand || this.props.definitionService.getAll

    getAllCommand().then((definitions) => {
      if (this._isMounted) {
        this.sortDefinitions(definitions, this.state.categories)
        this.setState({ definitions }, EventBus.fireFunc('definitions-rendered'))
      }
    })

    return null
  }

  reloadProductLinks = () => {
    getDataService().ProductLinks.getAll().then((productLinks) => {
      if (this._isMounted) {
        this.setState({ productLinks }, EventBus.fireFunc('definitions-rendered'))
      }
    })
  }

  sortDefinitions = (definitions, categories) => {
    const compareFunc = Utils.getDefinitionCompareFunction(User.getLanguage(), categories)
    definitions.sort(compareFunc)
  }

  isRowExpanded = (definitionId) => {
    return this.state.expanded[definitionId]
  }

  setRowExpanded = (definitionId: string, expanded: boolean) => {
    const expandedRows = CommonUtils.clone(this.state.expanded)
    expandedRows[definitionId] = expanded
    this.setState({ expanded: expandedRows })
  }

  getNameCell = (definition) => {
    let nameElement = definition.name

    if (definition.discontinued) {
      nameElement = (
        <span>
          <span style={{ textDecoration: 'line-through' }}>
            {definition.name}
          </span>
          {' ('}
          {i18n.t('common.discontinued').toLowerCase()}
          )
        </span>
      )
    }

    if (!this.props.isProduction) {
      return <td>{nameElement}</td>
    }

    const isExpanded = this.isRowExpanded(definition._id)

    return (
      <td
        style={{ cursor: 'pointer' }}
        onClick={() => {
          this.setRowExpanded(definition._id, !isExpanded)
        }}
      >
        <img src={'/img/' + (isExpanded ? 'expanded' : 'collapsed') + '.png'} />
        {' '}
        {nameElement}
      </td>
    )
  }

  getFilterConf = () => {
    const language = User.getLanguage()

    const names = Utils.getSortedDefinitionNames(this.state.definitions)

    const categories = this.state.categories.slice()

    categories.sort(function(cat1, cat2) {
      return cat1.labels[language].localeCompare(cat2.labels[language])
    })

    return {
      name: {
        type: 'predefined',
        labelKey: 'common.name',
        options: names.map(function(defName) {
          return { value: defName, label: defName }
        }),
      },
      category: {
        type: 'predefined',
        labelKey: 'common.category',
        options: categories.map(function(category) {
          return {
            value: category._id,
            label: category.labels[language],
          }
        }),
      },
    }
  }

  renderCurrencyHeaderCell = (translationKey, currency) => {
    return (
      <th key={currency}>
        {i18n.t(translationKey) + ' (' + currency + ')'}
      </th>
    )
  }

  renderCostHeaderCell = (currency) => {
    return this.renderCurrencyHeaderCell('common.cost', currency)
  }

  renderPriceHeaderCell = (currency) => {
    return this.renderCurrencyHeaderCell('common.price', currency)
  }

  renderCostOrPriceHeaderCells = () => {
    const renderCell = this.props.isProduction ? this.renderCostHeaderCell : this.renderPriceHeaderCell

    return this.props.currencies.map(renderCell)
  }

  renderCostDiv = (translationKey, cost) => {
    if (!cost) {
      return null
    }

    return (
      <div className="attr-option">
        {i18n.t(translationKey) + ': ' + CommonUtils.formatDecimal(cost, true)}
      </div>
    )
  }

  renderTailorCostDiv = (cost) => {
    return this.renderCostDiv('tpl-def.tailor-cost', cost)
  }

  renderFabricCostDiv = (cost) => {
    return this.renderCostDiv('tpl-def.fabric-cost', cost)
  }

  renderCostCell = (tplDef, currency) => {
    return (
      <td key={currency}>
        {this.renderTailorCostDiv(tplDef.tailorCosts[currency])}
        {this.renderFabricCostDiv(tplDef.fabricCosts[currency])}
      </td>
    )
  }

  renderPriceCell = (price, currency) => {
    const priceStr = price ? CommonUtils.formatDecimal(price, true) : ''
    return <td key={currency}>{priceStr}</td>
  }

  renderCostOrPriceCells = (definition) => {
    if (this.props.isProduction) {
      return this.props.currencies.map((currency) => {
        return this.renderCostCell(definition, currency)
      })
    }
    else {
      return this.props.currencies.map((currency) => {
        return this.renderPriceCell(definition.prices[currency], currency)
      })
    }
  }

  getActionCell = (definition) => {
    const modalId = 'edit-modal-' + definition._id

    const EditModal = this.props.editModal

    return (
      <td style={{ whiteSpace: 'nowrap' }}>
        <img
          className="table-btn btn-edit"
          src="img/edit.png"
          title={i18n.t('action.edit')}
          onClick={function() {
            EventBus.fire('open-modal', { modalId })
          }}
        />
        <img
          className="table-btn btn-delete"
          src="img/delete.png"
          title={i18n.t('action.delete')}
          onClick={() => {
            Utils.confirmTr('confirm.delete.definition').then((confirmed) => {
              if (confirmed) {
                return this.props.definitionService.delete(definition._id)
                // TODO: just remove it from the loaded list?
                .then(this.reloadDefinitions)
              }
            })
          }}
        />
        <EditModal
          modalId={modalId}
          definition={definition}
          categories={this.state.categories}
          attributeDefinitions={this.state.attributeDefinitions}
          // TODO: possible to optimize by just adding the
          // new object from the edit form's state
          afterSave={this.reloadDefinitions}
        />
      </td>
    )
  }

  render() {
    const menus = [
      <MainMenu key="main" activeTab="products" />,
      Access.seeInventory(User.getUser()) && (
          <DefinitionsMenu key="def" activeTab={this.props.menuTabId} />
      ),
    ]

    if (!this.state.loaded) {
      return (
        <div>
          {menus}
          {i18n.t('common.loading')}
        </div>
      )
    }

    const hasPermissions = Access.manageDefinitions(User.getUser())

    const filterManager = Filter.createManager(
      'definitions', this.getFilterConf(), this.state.definitions,
    )

    const colCount = (
      2 + this.props.currencies.length +
      this.state.attributeDefinitions.length + (hasPermissions ? 1 : 0)
    )

    const headerRow = (
      <tr>
        <th>
          {i18n.t('common.name')}
          {filterManager.getIcon('name')}
        </th>
        <th>
          {i18n.t('common.category')}
          {filterManager.getIcon('category')}
        </th>
        {this.renderCostOrPriceHeaderCells()}
        {this.props.menuTabId === 'products' && (
          <th>
            {i18n.t('common.fabricUsage')} (m)
          </th>
        )}
        {this.state.attributeDefinitions.map(function(attr) {
          return (
            <th key={attr._id}>
              {attr.pluralNames[User.getLanguage()]}
            </th>
          )
        })}
        {hasPermissions && <th>{i18n.t('action.action')}</th>}
      </tr>
    )

    let definitionRows = filterManager.getFiltered().map((definition) => {
      const dynamicAttributeCells = this.state.attributeDefinitions.map(
        (attribute) => {
          const combinations = (
            definition.attributeCombinations[attribute._id] || []
          ) as (number | AttributeCombination)[]

          return (
            <td key={attribute._id}>
              {combinations.map((comboParam) => {
                const combination = Utils.ensureCombination(
                  comboParam, attribute, definition, this.state.templateDefinitions,
                )

                return (
                  <div key={combination.id} className="attr-option">
                    {CommonUtils.getAttributeComboLabel(
                      attribute, combination.values, definition.category, User.getLanguage(),
                    )}
                  </div>
                )
              })}
            </td>
          )
        },
      )

      const category = CommonUtils.findById(this.state.categories, definition.category)

      const row = (
        <tr className="row-def" key="main">
          {this.getNameCell(definition)}
          <td>{category.labels[User.getLanguage()]}</td>
          {this.renderCostOrPriceCells(definition)}
          {this.props.menuTabId === 'products' && (
            <td>{'fabricUsage' in definition ? definition.fabricUsage : ''}</td>
          )}
          {dynamicAttributeCells}
          {hasPermissions ? this.getActionCell(definition) : null}
        </tr>
      )

      let detailRow = null

      if (this.isRowExpanded(definition._id)) {
        const productLinks = this.state.productLinks[definition._id]
        const prodDefModalId = 'add-prod-def-modal-' + definition._id

        let links

        if (productLinks) {
          links = productLinks.map(function(productLink, index) {
            const style: CSSProperties = { paddingLeft: '0.4em' }

            if (productLink.discontinued) {
              style.textDecoration = 'line-through'
            }

            return <div key={index} style={style}>{productLink.name}</div>
          })
        }
        else {
          links = (
            <div style={{ fontStyle: 'italic' }}>
              {i18n.t('tpl-def.no-product-links')}
            </div>
          )
        }

        let defineNewButton = null
        let prodDefModal = null

        if (hasPermissions && !definition.discontinued) {
          defineNewButton = (
            <button
              style={{ marginTop: '0.3em' }}
              onClick={function() {
                EventBus.fire('open-modal', { modalId: prodDefModalId })
              }}
            >
              {i18n.t('prod-def.define-new')}
            </button>
          )

          const productAttrDefs = this.state.unfilteredAttributeDefinitions.filter(
            CommonUtils.attributeContextFilters.products,
          )

          const ProductDefinitionModal = this.props.productDefinitionModal

          prodDefModal = (
            <ProductDefinitionModal
              modalId={prodDefModalId}
              isNew={true}
              template={definition._id}
              categories={this.state.categories}
              attributeDefinitions={productAttrDefs}
              afterSave={this.reloadProductLinks}
            />
          )
        }

        detailRow = (
          <tr key="detail">
            <td colSpan={7} style={{ padding: '0.7em' }}>
              <div style={{ fontWeight: 'bold' }}>
                {i18n.t('tpl-def.product-links')}
              </div>
              {links}
              {defineNewButton}
              {prodDefModal}
            </td>
          </tr>
        )
      }

      return (
        <Fragment key={definition._id}>
          {row}
          {detailRow}
        </Fragment>
      )
    })

    if (!definitionRows.length) {
      const anyFilters = filterManager.anyFilters()

      definitionRows = [
        <tr key="no-items">
          <td colSpan={colCount}>
            {anyFilters ? i18n.t('definition.no-filtered-items') : i18n.t('definition.no-items')}
          </td>
        </tr>,
      ]
    }

    let defineNewButton = null

    if (hasPermissions) {
      // For template definitions, the button is above the table.
      // For product definitions, it's below the table.

      defineNewButton = (
        <button
          id="btn-define-new"
          style={{ marginBottom: 5 }}
          onClick={function() {
            EventBus.fire('open-modal', { modalId: 'add-modal' })
          }}
        >
          {i18n.t(this.props.isProduction ? 'tpl-def.define-new' : 'prod-def.define-new')}
        </button>
      )
    }

    const EditModal = this.props.editModal

    return (
      <div>
        {menus}
        {this.props.isProduction ? defineNewButton : null}
        {filterManager.getSummary()}
        <table
          id="tbl-def"
          className="table table-bordered table-condensed table-striped"
        >
          <FloatingHeader headerRow={headerRow} />
          <tbody>
            {definitionRows}
          </tbody>
        </table>
        {!this.props.isProduction && defineNewButton}
        <EditModal
          modalId="add-modal"
          isNew={true}
          categories={this.state.categories}
          attributeDefinitions={this.state.attributeDefinitions}
          // TODO: only load the added definition?
          afterSave={this.reloadDefinitions}
        />
      </div>
    )
  }
}
