import { ReadStream } from 'fs'

import type { ObjectId } from 'mongodb'

import { DetailedEntry } from './common-utils'

import {
  AttributeDefinition,
  AttributeDefinitionUsage,
  AttributeDefinitionUpdate,
  AttributeDefinitionCreate,
  Username,
} from './data-attribute-defs'

import { CartEntry } from './data-carts'
import { Correction } from './data-correction-report'
import { Customer } from './data-customers'
import { EarningCategory } from './data-earning-categories'
import { Earning, EarningInput } from './data-earnings'
import { ServiceError, UiError } from './data-error-report'
import { ExpenseCategory } from './data-expense-categories'
import { Expense, ExpenseInput } from './data-expenses'

import {
  ActualPaymentsInput,
  DetailedInvoice,
  Invoice,
  InvoiceBookInput,
  InvoiceConfirm,
  InvoiceEntryInput,
} from './data-invoices'

import { LocationCreate, Location, LocationType, LocationUpdate } from './data-locations'
import { MaterialConsumption, MaterialConsumptionUpdate } from './data-material-consumption-report'
import { MaterialOrder, MaterialOrderCreate, MaterialOrderUpdate } from './data-material-order'
import { MaterialYearlyReport, MaterialYearlyReportGetAllFilters } from './data-material-yearly-report'

import {
  SimpleAttr,
  UserCountry,
  Settings,
  SettingsInput,
  SimpleAttrInput,
  UserCountryInput,
} from './data-misc'

import {
  CustomDefinitionCreate,
  CustomDefinitionUpdate,
  PricesInput,
  ProductDefinition,
  ProductDefinitionCreate,
  ProductDefinitionUpdate,
} from './data-product-defs'

import { ProductLink } from './data-product-links'
import { Production } from './data-production-report'
import { Product, ProductCreate, ProductUpdate } from './data-products'
import { RawMaterialCreate, RawMaterial, RawMaterialUpdate } from './data-raw-materials'
import { CreateTailorOrder, CreateTailorOrderComment, TailorOrder, TailorOrderComment, UpdateTailorOrder, UpdateTailorOrderComment } from './data-tailor-order'
import { TemplateDefinition, TemplateDefinitionCreate, TemplateDefinitionUpdate } from './data-template-defs'
import { Template, TemplateCreate, TemplateUpdate } from './data-templates'
import { Transfer, TransferFilters } from './data-transfers'
import { UserInput, UiUser } from './data-users'
import { AugmentedCategory } from './expense-category-path-builder'
import { ExpenseSummaryItem } from './expense-summary-calculator'
import { Transaction } from './transaction-utils'
import { AttributeCombination, AttributeUsage } from './types'

export interface UserOps {
  getAll: () => Promise<UiUser[]>,
  getUsernames: () => Promise<Username[]>,
  create: (obj: UserInput) => Promise<{ success: true, id: string }>,
  update: (id: string, obj: UserInput, oldPassword?: string) => Promise<void>,
  deactivate: (id: string) => Promise<void>,
  activate: (id: string) => Promise<void>,
  delete: (id: string) => Promise<void>,
}

export interface UserCountryOps {
  getAll: () => Promise<UserCountry[]>,
  create: (obj: UserCountryInput) => Promise<{ success: true, id: string }>,
  update: (id: string, obj: UserCountryInput) => Promise<void>,
  delete: (id: string) => Promise<void>,
}

export interface SimpleAttrOps {
  getAll: () => Promise<SimpleAttr[]>,
  create: (obj: SimpleAttrInput) => Promise<void>,
  update: (id: string, obj: SimpleAttrInput) => Promise<void>,
  delete: (id: string) => Promise<void>,
}

export interface SettingsOps {
  get: () => Promise<Settings>,
  set: (obj: SettingsInput) => Promise<void>,
}

export interface LocationOps {
  getAll: () => Promise<Location[]>,
  getByType: (type: LocationType | 'sales-points') => Promise<Location[]>,
  getById: (id) => Promise<Location | null>,
  create: (obj: LocationCreate) => Promise<{ success: true, id: string }>,
  update: (id: string, obj: LocationUpdate) => Promise<void>,
  delete: (id: string) => Promise<void>,
}

export interface AttributeDefinitionOps {
  getAll: () => Promise<AttributeDefinition[]>,
  create: (obj: AttributeDefinitionCreate) => Promise<{ success: true, id: string }>,
  update: (id: string, obj: AttributeDefinitionUpdate) => Promise<void>,
  moveDown: (id: string) => Promise<void>,
  getUsage: (id: string) => Promise<AttributeDefinitionUsage>,
}

export interface RawMaterialOps {
  getAll: () => Promise<RawMaterial[]>,
  create: (obj: RawMaterialCreate) => Promise<{ success: true, id: string }>,
  update: (
    id: string,
    obj: RawMaterialUpdate,
    removePhoto: boolean,
    photoBlob: Blob | ReadStream | null,
  ) => Promise<void>,
  delete: (id: string) => Promise<void>,
  addToAmount: (id: string, amountToAdd: string, newPrice?: string) => Promise<void>,
  cutAmount: (id: string, amountToCut: string, note?: string) => Promise<void>,
}

export interface TemplateDefinitionOps {
  getAll: () => Promise<TemplateDefinition[]>,
  create: (obj: TemplateDefinitionCreate) => Promise<{ success: true, id: string }>,
  update: (id: string, obj: TemplateDefinitionUpdate) => Promise<void>,
  delete: (id: string) => Promise<void>,
  getAttributeUsage: (id: string) => Promise<AttributeUsage>,
}

export interface ProductDefinitionOps {
  getAll: () => Promise<ProductDefinition[]>,
  getNames: () => Promise<string[]>,
  getNonCustom: () => Promise<ProductDefinition[]>,
  getByTemplate: (template: string) => Promise<ProductDefinition[]>,
  getByInventoryLocation: (loc: string) => Promise<ProductDefinition[]>,
  create: (obj: ProductDefinitionCreate) => Promise<{ success: true, id: string }>,
  update: (id: string, obj: ProductDefinitionUpdate) => Promise<void>,
  delete: (id: string) => Promise<void>,
  getAttributeUsage: (id: string) => Promise<AttributeUsage>,
}

export interface ProductLinkOps {
  getAll: () => Promise<Record<string, ProductLink[]>>,
}

export interface TemplateOps {
  getAll: () => Promise<Template[]>,
  getByLocation: (loc: string) => Promise<Template[]>,
  create: (obj: TemplateCreate) => Promise<{ success: true, id: string }>,
  update: (id: string, obj: TemplateUpdate) => Promise<void>,
  archive: (id: string) => Promise<void>,
  delete: (id: string) => Promise<void>,
  addToAmount: (id: string, amountToAdd: string) => Promise<void>,
  transform: (
    templateId: string,
    productDefinitionId: string,
    attributes: Record<string, number>,
    amount: string,
    orderId?: string,
  ) => Promise<{ success: true, id: string }>,
}

export interface ProductOps {
  getAll: () => Promise<Product[]>,
  getByLocation: (loc: string) => Promise<Product[]>,
  create: (obj: ProductCreate) => Promise<{ success: true, id: string }>,
  createCustom: (
    definition: CustomDefinitionCreate,
    item: ProductCreate,
  ) => Promise<{
    success: true,
    definitionId: string,
    productId: string,
  }>,
  update: (
    id: string,
    obj: ProductUpdate,
    definition: CustomDefinitionUpdate | undefined,
  ) => Promise<{
    success: true,
    noChanges?: true,
  }>,
  addToAmount: (id: string, amountToAdd: string) => Promise<void>,
  setRawMaterial: (id: string, rawMaterialId: string) => Promise<void>,
  convertToCustom: (id: string) => Promise<{ success: true, definitionId: string }>,
}

export interface CartOps {
  getContents: (loc: string) => Promise<CartEntry[]>,
  getDetailedContents: (loc: string) => Promise<DetailedEntry[]>,
  getCount: () => Promise<number>,
  addAmount: (productId: string, amountToAdd: string) => Promise<void>,
  editAmount: (productId: string, amount: string) => Promise<void>,
  getLocations: () => Promise<string[]>,
}

export interface InvoiceOps {
  getAll: () => Promise<Invoice[]>,
  getArchived: () => Promise<Invoice[]>,
  getById: (id: number | string) => Promise<Invoice>,
  getDetailed: (id: string) => Promise<DetailedInvoice>,
  confirm: (invoice: InvoiceConfirm) => Promise<{ success: true, id: number }>,
  correct: ( // TODO parameter object
    id: number | string,
    entries: InvoiceEntryInput[],
    customerName: string | undefined,
    customerContact: string | undefined,
    note: string | undefined,
    book: InvoiceBookInput | undefined,
    shipping: string | undefined,
    isExport: boolean,
    actualPayments: ActualPaymentsInput,
    guideCommission: boolean | undefined,
    marraCashCard: boolean | undefined,
  ) => Promise<void>,
  undo: (id: number) => Promise<void>,
  archiveYear: (year: number) => Promise<void>,
  dearchiveYear: (year: number) => Promise<void>,
}

export interface CustomerOps {
  getAll: () => Promise<Customer[]>,
  update: (id: string, contact: string | undefined) => Promise<void>,
  delete: (id: string) => Promise<void>,
}

export interface TransferOps {
  getAll: (filters?: TransferFilters) => Promise<Transfer[]>,
  getById: (id: string) => Promise<Transfer | null>,
  create: (
    fromLoc: string,
    toLoc: string,
    entries: CartEntry[],
    clearCart: boolean,
  ) => Promise<{ success: true, id: string }>,
  update: (id: string, entries: CartEntry[]) => Promise<void>,
  send: (id: string) => Promise<void>,
  delete: (id: string) => Promise<void>,
  accept: (id: string, corrections?: CartEntry[]) => Promise<unknown>,
}

export interface ProductionReportOps {
  getAll: () => Promise<Production[]>,
  update: (
    id: ObjectId,
    entry: {
      amount: string,
      time: string,
    },
    attributes: Record<string, string | number | number[] | AttributeCombination[]>,
    prodName?: string,
    orderId?: string,
    prices?: PricesInput,
  ) => Promise<void>,
  undo: (id: ObjectId) => Promise<void>,
}

export interface MaterialConsumptionReportOps {
  getAll: () => Promise<MaterialConsumption[]>,
  update: (id: ObjectId, obj: MaterialConsumptionUpdate) => Promise<void>,
  undo: (id: ObjectId) => Promise<void>,
}

export interface CorrectionReportOps {
  getAll: () => Promise<Correction[]>,
}

export interface ErrorReportOps {
  getServiceErrors: () => Promise<ServiceError[]>,
  hideServiceError: (id: ObjectId) => Promise<void>,
  getUiErrors: () => Promise<UiError[]>,
  hideUiError: (id: ObjectId) => Promise<void>,
  getFailedTransactions: () => Promise<Transaction[]>,
  hideFailedTransaction: (id: ObjectId) => Promise<void>,
}

export interface ExpenseOps {
  getAll: () => Promise<Expense[]>,
  create: (obj: ExpenseInput) => Promise<{ success: true, id: number }>,
  update: (id: number, obj: ExpenseInput) => Promise<void>,
  delete: (id: number) => Promise<void>,
  summary: (start: string, end: string) => Promise<ExpenseSummaryItem[]>,
}

export interface ExpenseCategoryOps {
  getAll: () => Promise<AugmentedCategory[]>,
  create: (labelEn: string, labelFr: string, parentId: string) => Promise<{ success: true, id: string }>,
  update: (id: string, obj: ExpenseCategory) => Promise<void>,
  delete: (id: string) => Promise<void>,
}

export interface EarningOps {
  getAll: () => Promise<Earning[]>,
  create: (obj: EarningInput) => Promise<{ success: true, id: number }>,
  update: (id: string, obj: EarningInput) => Promise<void>,
  delete: (id: string) => Promise<void>,
  summary: (start: string, end: string) => Promise<Record<string, number>>,
}

export interface EarningCategoryOps {
  getAll: () => Promise<EarningCategory[]>,
  create: (labelEn: string, labelFr: string) => Promise<{ success: true, id: string }>,
  update: (id: string, obj: EarningCategory) => Promise<void>,
  delete: (id: string) => Promise<void>,
}

export interface MaterialYearlyReportOps {
  getAll: (filters: MaterialYearlyReportGetAllFilters) => Promise<MaterialYearlyReport[]>
}

export interface MaterialOrderOps {
  getAll: () => Promise<MaterialOrder[]>,
  create: (obj: MaterialOrderCreate) => Promise<{ success: true, id: string }>,
  update: (id: string, obj: MaterialOrderUpdate) => Promise<void>,
  delete: (id: string) => Promise<void>,
  updateMaterialStock: (id: string) => Promise<void>
}

export interface TailorOrderOps {
  getAll: () => Promise<TailorOrder[]>,
  create: (obj: CreateTailorOrder) => Promise<{ success: true, id: string }>,
  update: (id: string, obj: UpdateTailorOrder) => Promise<void>,
  getOrderComments: (orderId: string) => Promise<TailorOrderComment[]>,
  createComment: (orderId: string, obj: CreateTailorOrderComment) => Promise<{ success: true, id: string }>,
  updateComment: (orderId: string, commentId: string, obj: UpdateTailorOrderComment) => Promise<void>,
  deleteComment: (orderId: string, commentId: string) => Promise<void>
}

export interface DataService {
  Users: UserOps,
  UserCountries: UserCountryOps,
  Categories: SimpleAttrOps,
  MaterialCategories: SimpleAttrOps,
  MaterialProducers: SimpleAttrOps,
  MaterialWidths: SimpleAttrOps,
  Settings: SettingsOps,
  Locations: LocationOps,
  AttributeDefinitions: AttributeDefinitionOps,
  RawMaterials: RawMaterialOps,
  TemplateDefinitions: TemplateDefinitionOps,
  ProductDefinitions: ProductDefinitionOps,
  ProductLinks: ProductLinkOps,
  Templates: TemplateOps,
  Products: ProductOps,
  Carts: CartOps,
  Invoices: InvoiceOps,
  Customers: CustomerOps,
  Transfers: TransferOps,
  ProductionReport: ProductionReportOps,
  MaterialConsumptionReport: MaterialConsumptionReportOps,
  CorrectionReport: CorrectionReportOps,
  ErrorReport: ErrorReportOps,
  Expenses: ExpenseOps,
  ExpenseCategories: ExpenseCategoryOps,
  Earnings: EarningOps,
  EarningCategories: EarningCategoryOps,
  MaterialYearlyReports: MaterialYearlyReportOps,
  MaterialOrders: MaterialOrderOps,
  TailorOrders: TailorOrderOps
}

let dataService: DataService
export const setDataService = (value: DataService) => { dataService = value }
export const getDataService = () => dataService
