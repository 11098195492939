import React, { FC, useMemo, useRef, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'

import {
  MaterialOrder,
  AdditionalExpense,
} from '../../../common/data-material-order'
import { MaterialProducer } from '../../../common/data-misc'
import { RawMaterial } from '../../../common/data-raw-materials'
import { getDataService } from '../../../common/data-service'
import { i18n } from '../../../common/i18n'
import { CurrencyWithUsd } from '../../../common/invoice-utils'
import { getDefaultCancelButton, Modal } from '../../modal'
import { Column, UiUtils } from '../../ui-utils'
import { useOpenModalEvent } from '../../use-open-modal-event'
import { Utils } from '../../utils'

interface ShowMaterialOrdersInvoiceProps {
  modalId: string,
  materials: RawMaterial[],
  order: MaterialOrder,
  producer: MaterialProducer,
  currencyData: CurrencyData,
  isManager: boolean,
  afterSave: () => void,
}
interface CurrencyData {
  usdRate: number,
  euroRate: number,
}

interface Row {
  id: string,
  name: string,
  quantity: number,
  prixEur: number,
  prixUsd: number,
  prixMad: number,
  totalEur: number,
  totalUsd: number,
  totalMad: number,
  coutTotal: number,
  coutUnitareMad: number,
}

interface AdditionalCost {
  shipping?: number,
  analysis?: number,
  storage?: number,
  delivery?: number,
  customs?: number,
  other?: number,
}

export const ShowMaterialOrdersInvoice: FC<ShowMaterialOrdersInvoiceProps> = ({
  materials,
  order,
  currencyData,
  modalId,
  isManager,
  afterSave,
}) => {
  const DataService = getDataService()
  const formRef = useRef(null)
  const [isVisible, close] = useOpenModalEvent(modalId)
  const [currencyType, setCurrencyType] = useState<CurrencyWithUsd>(order?.originalCurrency?.currency || 'EUR')
  const [formValues, setFormValues] = useState({
    order: order.materials.sort(sorrMaterials),
    euroRate: currencyData.euroRate,
    usdRate: currencyData.usdRate,
    madRate: 1,
    additionalExpenses: order.additionalExpenses.reduce<AdditionalCost>(
      (a, v) => ({ ...a, [v.type]: v.cost }),
      {},
    ),
  })
  const currency: CurrencyWithUsd[] = ['EUR', 'USD', 'MAD']
  const isReceived = useMemo(() => order.status === 'received', [order.status])
  const isStockUpdated = useMemo(() => order.stockUpdated, [order.stockUpdated])
  const shippingMinValue = useMemo(() => {
    let value = 0
    formValues.order.forEach((ordr) => {
      value += ordr.amount * ordr.originalCost
    })
    return value
  }, [formValues])
  const isShippingAdded = useMemo(() => Boolean(formValues.additionalExpenses?.shipping) && formValues.additionalExpenses?.shipping >= shippingMinValue, [formValues.additionalExpenses, shippingMinValue])
  const disabledAddToStock = useMemo(() => {
    return !isManager || !isReceived || isStockUpdated || !isShippingAdded
  }, [isManager, isReceived, isStockUpdated, isShippingAdded])
  if (!isVisible) {
    return <div />
  }
  function sorrMaterials(a, b) {
    return (a.material || a._id) > (b.material || b._id) ? 1 : -1
  }

  function findAdditionalOriginalCost(name: string) {
    return (
      order.additionalExpenses?.find(
        (item: AdditionalExpense) => item.type === name,
      )?.originalCost || 0
    )
  }

  const getUpdateData = () => {
    const additionalExpensesValue = Object.entries(formValues.additionalExpenses)
    const formatExpensesData = additionalExpensesValue.map(
      (item) => ({
        type: item[0].toString(),
        cost: Number(item[1]),
        originalCost: findAdditionalOriginalCost(item[0]),
      }),
    ) as AdditionalExpense[]

    return {
      materials: formValues.order.map((item) => ({
        amount: Number(item.amount),
        material: item.material,
        // cost: Number(item.cost), // we changed it to unit cost
        cost: Utils.sumTotalForInvoice(
          formValues.order,
          Number(item.originalCost),
          Number(item.amount),
          formValues.additionalExpenses,
          getCurrency(),
        ) / Number(item.amount), // the unit cost here
        originalCost: Number(item.originalCost), // not converted
      })),
      additionalExpenses: formatExpensesData,
      status: order.status,
      originalCurrency: {
        currency: currencyType,
        exchangeRate: currencyType === 'EUR' ? currencyData.euroRate : currencyType === 'USD' ? currencyData.usdRate : 1,
      },
    }
  }

  const handleSaveData = async () => {
    await DataService.MaterialOrders.update(order._id, getUpdateData())
  }

  const handleAddToStock = async () => {
    await DataService.MaterialOrders.update(order._id,
      {
        ...getUpdateData(),
        status: 'received',
      })
      .then(() => {
        DataService.MaterialOrders.updateMaterialStock(order._id).then(afterSave).then(close)
      })
  }

  const handleAdditionalOnChange = (value: number | string, name: string) => {
    setFormValues((values) => ({
      ...values,
      additionalExpenses: {
        ...values.additionalExpenses,
        [name]: value,
      },
    }))
  }

  const handleChangeOrderValues = (
    value: string,
    id: string,
    field: string,
  ) => {
    const updateOrder = formValues.order.map((itm) =>
      itm.material === id ?
        {
          ...itm,
          [field]: value,
        } :
        itm,
    )
    setFormValues((values) => ({ ...values, order: updateOrder }))
  }

  const renderCurrencyColumns = () =>
  ({
    MAD: [
      {
        id: 'prixMAD',
        header: i18n.t('material-orders.show-invoice.prix-mad'),
        excelWidth: 80,
        getCellContents: (item) => {
          return (
            <div>
              <CurrencyInput
                style={{ width: '100%' }}
                value={item.prixMad}
                decimalsLimit={2}
                allowNegativeValue={false}
                decimalScale={2}
                decimalSeparator="."
                onValueChange={(value) => handleChangeOrderValues(value, item.id, 'originalCost')} // we use originalCost instead of cost
                disabled={isStockUpdated}
              />
            </div>
          )
          // return <div>{formValues.prixMAD}</div>
        },
      },
    ],
    USD: [
      {
        id: 'prixUsd',
        header: i18n.t('material-orders.show-invoice.prix-usd'),
        excelWidth: 80,
        getCellContents: (item) => {
          return (
            <div>
              <CurrencyInput
                style={{ width: '100%' }}
                value={item.prixUsd}
                decimalsLimit={2}
                allowNegativeValue={false}
                decimalScale={2}
                decimalSeparator="."
                onValueChange={(value) => handleChangeOrderValues(value, item.id, 'originalCost')}
                disabled={isStockUpdated}
              />
            </div>
          )
        },
      },
      {
        id: 'totalUsd',
        header: i18n.t('material-orders.show-invoice.total-usd'),
        excelWidth: 80,
      },
    ],
    EUR: [
      {
        id: 'prixEur',
        header: i18n.t('material-orders.show-invoice.prix-euro'),
        excelWidth: 80,
        getCellContents: (item) => {
          return (
            <div>
              <CurrencyInput
                style={{ width: '100%' }}
                value={item.prixEur}
                decimalsLimit={2}
                allowNegativeValue={false}
                decimalScale={2}
                decimalSeparator="."
                onValueChange={(value) => handleChangeOrderValues(value, item.id, 'originalCost')}
                disabled={isStockUpdated}
              />
            </div>
          )
        },
      },
      {
        id: 'totalEur',
        header: i18n.t('material-orders.show-invoice.total-euro'),
        excelWidth: 80,
      },
    ],
  }[currencyType])

  const getCurrency = () =>
  ({
    MAD: formValues.madRate,
    EUR: formValues.euroRate,
    USD: formValues.usdRate,
  }[currencyType])

  const getColumnConf = () => {
    const columns: Column<Row>[] = [
      {
        id: 'name',
        header: i18n.t('material-orders.show-invoice.name'),
        excelWidth: 100,
      },
      {
        id: 'quantity',
        header: i18n.t('material-orders.show-invoice.quantity'),
        excelWidth: 60,
        getCellContents: (item) => {
          return (
            <div>
              <CurrencyInput
                style={{ width: '100%' }}
                value={item.quantity}
                decimalsLimit={2}
                allowNegativeValue={false}
                decimalScale={2}
                decimalSeparator="."
                onValueChange={(value) => handleChangeOrderValues(value, item.id, 'amount')}
                disabled={isStockUpdated}
              />
            </div>
          )
        },
      },
      ...renderCurrencyColumns(),
      {
        id: 'totalMad',
        header: i18n.t('material-orders.show-invoice.total-mad'),
        excelWidth: 80,
      },
      {
        id: 'coutTotal',
        header: i18n.t('material-orders.show-invoice.total-cost'),
        excelWidth: 80,
      },
      {
        id: 'coutUnitareMad',
        header: i18n.t('material-orders.show-invoice.total-unitare'),
        excelWidth: 100,
      },
      // renderUnitareMadColumn(),
    ]

    return columns
  }

  const onCurrencySelect = (value: CurrencyWithUsd) => setCurrencyType(value)

  const getRowsData = (materialsData: RawMaterial[]) => {
    return materialsData
      .sort(sorrMaterials)
      .map((item: RawMaterial, index: number) => {
        const currentOrderAmount = formValues.order[index].amount
        const currentOrderCost = formValues.order[index].originalCost
        // const isMad = currencyType === 'MAD'
        const totalValues = Utils.sumTotalForInvoice(
          formValues.order,
          currentOrderCost,
          currentOrderAmount,
          formValues.additionalExpenses,
          getCurrency(),
        )
        return {
          id: item._id,
          name: item.name,
          quantity: currentOrderAmount || 0,
          prixEur: currentOrderCost || 0,
          prixUsd: currentOrderCost || 0,
          prixMad: currentOrderCost || 1,
          totalEur: Math.floor(currentOrderAmount * currentOrderCost),
          totalUsd: Math.floor(currentOrderAmount * currentOrderCost),
          totalMad: Math.floor((currentOrderAmount * currentOrderCost) * getCurrency()),
          coutTotal: totalValues,
          coutUnitareMad: Math.floor(totalValues / currentOrderAmount),
        }
      })
  }

  const renderTable = () => {
    const context = {}
    const rowsData = getRowsData(materials)

    return UiUtils.getTable(getColumnConf(), rowsData, {
      tableId: 'tbl-mat',
      tableClassName: 'table table-bordered table-condensed table-striped',
      rowClassName: 'row-mat',
      context,
      noFilteredItemsText: i18n.t('material-orders.no-filtered-items'),
    })
  }

  const renderAdditionalTable = () => {
    const additionalExpensesValue = formValues.additionalExpenses
    return (
      <div className="additional-table">
        <p className="additional-table__title">Additional costs</p>
        <div className="additional-table__row">
          <div>Invoice with shipping ({currencyType})</div>
          <div>
            <CurrencyInput
              value={additionalExpensesValue.shipping}
              decimalsLimit={2}
              allowNegativeValue={false}
              decimalScale={2}
              decimalSeparator="."
              onValueChange={(value) => handleAdditionalOnChange(value, 'shipping')}
              disabled={isStockUpdated}
              min={shippingMinValue}
              step={1}
              onBlur={(event) => {
                if (shippingMinValue && Number(event.target.value) < shippingMinValue) {
                  handleAdditionalOnChange(shippingMinValue, 'shipping')
                }
              }}
            />
          </div>
        </div>
        {currencyType !== 'MAD' && (
          <div className="additional-table__row">
            <div>Invoice with shipping (MAD)</div>
            <div>
              {Object.values(additionalExpensesValue).length ? Math.floor(additionalExpensesValue.shipping * getCurrency()) : '0'}
            </div>
          </div>
        )}
        <div className="additional-table__row">
          <div>Analysis (of fabric in a lab)</div>
          <div>
            <CurrencyInput
              value={additionalExpensesValue.analysis}
              decimalsLimit={2}
              allowNegativeValue={false}
              decimalScale={2}
              decimalSeparator="."
              onValueChange={(value) => handleAdditionalOnChange(value, 'analysis')}
              disabled={isStockUpdated}
            />
          </div>
        </div>

        <div className="additional-table__row">
          <div>Storage</div>
          <div>
            <CurrencyInput
              value={additionalExpensesValue.storage}
              decimalsLimit={2}
              allowNegativeValue={false}
              decimalScale={2}
              decimalSeparator="."
              onValueChange={(value) => handleAdditionalOnChange(value, 'storage')}
              disabled={isStockUpdated}
            />
          </div>
        </div>

        <div className="additional-table__row">
          <div>Delivery</div>
          <div>
            <CurrencyInput
              value={additionalExpensesValue.delivery}
              decimalsLimit={2}
              allowNegativeValue={false}
              decimalScale={2}
              decimalSeparator="."
              onValueChange={(value) => handleAdditionalOnChange(value, 'delivery')}
              disabled={isStockUpdated}
            />
          </div>
        </div>

        <div className="additional-table__row">
          <div>Customs</div>
          <div>
            <CurrencyInput
              value={additionalExpensesValue.customs}
              decimalsLimit={2}
              allowNegativeValue={false}
              decimalScale={2}
              decimalSeparator="."
              onValueChange={(value) => handleAdditionalOnChange(value, 'customs')}
              disabled={isStockUpdated}
            />
          </div>
        </div>

        <div className="additional-table__row">
          <div>Extra (field for anything extra there might be)</div>
          <div>
            <CurrencyInput
              value={additionalExpensesValue.other}
              decimalsLimit={2}
              allowNegativeValue={false}
              decimalScale={2}
              decimalSeparator="."
              onValueChange={(value) => handleAdditionalOnChange(value, 'other')}
              disabled={isStockUpdated}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Modal
        title={i18n.t('menu.main.material-orders')}
        closeModal={close}
        dialogClassName="add-material-order"
        size="xl"
        buttons={[
          {
            id: 'add-to-stock',
            title: i18n.t('action.add-to-stock'),
            disabled: disabledAddToStock,
            onClick: () => handleAddToStock(),
          },
          {
            id: 'btn-save',
            title: i18n.t('action.save'),
            onClick: () => handleSaveData().then(afterSave).then(close),
          },
          getDefaultCancelButton(),
        ]}
      >
        <form ref={formRef}>
          <select
            style={{ margin: '0 0 20px 0' }}
            onChange={(event) => onCurrencySelect(event.target.value as CurrencyWithUsd)}
            defaultValue={currencyType}
            disabled={isStockUpdated}
          >
            {currency.map((item: string, index: number) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              )
            })}
          </select>
          {renderTable()}
          {renderAdditionalTable()}
        </form>
      </Modal>
    </div>
  )
}
